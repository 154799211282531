import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { Header } from 'components/header'
import Main from 'components/main'
import Footer from 'components/footer'
// import Container from 'components/container'
// import { Solid, Gradient } from 'components/background'
import ProjectHeader from 'components/projectHeader'
import ImageBlock from 'components/imageBlock'
import TextBlock from 'components/textBlock'
import ThreeColumn from 'components/threeColumn'
import ProjectFooter from 'components/projectFooter'
import { graphql } from 'gatsby'

import Container from 'components/container'
import { InViewAnimationContainer } from 'components/imageBlock/imageBlock.css'
import kidwonderLogomark from 'gifs/kidwonder-logomark-anim.gif'

const Kidwonder = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.kidwonderJson.metaTitle}</title>
    </Helmet>
    <Header />
    <Main>
      <article>
        <ProjectHeader
          heading={data.kidwonderJson.heading}
          subheading={data.kidwonderJson.subheading}
          disciplines={data.kidwonderJson.disciplines}
          projectLink={data.kidwonderJson.projectLink}
          projectLinkCopy={data.kidwonderJson.projectLinkCopy}
          projectUrl={data.kidwonderJson.projectUrl}
        />
        <ImageBlock src={data.kidwonderJson.projectImages.imageOne} alt={data.kidwonderJson.metaTitle} />
        <TextBlock copy={data.kidwonderJson.copy} />
        <ImageBlock src={data.kidwonderJson.projectImages.imageTwo} alt={data.kidwonderJson.metaTitle} />

        <ThreeColumn>
          <ImageBlock
            src={data.kidwonderJson.projectImages.imageOneMobile}
            alt={data.kidwonderJson.metaTitle}
            noPadding={true}
          />
          <ImageBlock
            src={data.kidwonderJson.projectImages.imageTwoMobile}
            alt={data.kidwonderJson.metaTitle}
            noPadding={true}
          />
          <ImageBlock
            src={data.kidwonderJson.projectImages.imageThreeMobile}
            alt={data.kidwonderJson.metaTitle}
            noPadding={true}
          />
        </ThreeColumn>

        <Container>
          <InViewAnimationContainer>
            <img src={kidwonderLogomark} alt="Kidwonder Logomark" width="100%" />
          </InViewAnimationContainer>
        </Container>

        {/* <ImageBlock src={data.kidwonderJson.projectImages.imageThree} alt={data.kidwonderJson.metaTitle} /> */}

        <ImageBlock src={data.kidwonderJson.projectImages.imageFive} alt={data.kidwonderJson.metaTitle} />
        <ImageBlock src={data.kidwonderJson.projectImages.imageSix} alt={data.kidwonderJson.metaTitle} />

        <ThreeColumn>
          <ImageBlock
            src={data.kidwonderJson.projectImages.imageFourMobile}
            alt={data.kidwonderJson.metaTitle}
            noPadding={true}
          />
          <ImageBlock
            src={data.kidwonderJson.projectImages.imageFiveMobile}
            alt={data.kidwonderJson.metaTitle}
            noPadding={true}
          />
          <ImageBlock
            src={data.kidwonderJson.projectImages.imageSixMobile}
            alt={data.kidwonderJson.metaTitle}
            noPadding={true}
          />
        </ThreeColumn>

        <ImageBlock src={data.kidwonderJson.projectImages.imageFour} alt={data.kidwonderJson.metaTitle} />
      </article>
      <ProjectFooter link={data.kidwonderJson.nextLink} />
    </Main>
    <Footer />
  </Layout>
)

Kidwonder.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default Kidwonder

export const query = graphql`
  query KidwonderQuery {
    kidwonderJson {
      metaTitle
      metaDescription
      heading
      subheading
      disciplines
      copy
      projectLink
      projectLinkCopy
      projectUrl
      nextLink
      projectImages {
        imageOne {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageTwo {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageThree {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFour {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFive {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageSix {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageOneMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageTwoMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageThreeMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFourMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFiveMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageSixMobile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`
